/* CKEditor */
.ck.ck-balloon-panel {
  border: none;
  padding: 0px;
  box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.08);
}

.ck-rounded-corners .ck.ck-balloon-panel,
.ck-rounded-corners .ck.ck-input {
  border-radius: 4px;
}

.ck.ck-balloon-panel input {
  height: 54px;
  padding: 16px;
  width: 100%;
  height: 100%;
  color: #0076a8;
  caret-color: black;
}

.ck.ck-balloon-panel input:focus {
  border: 1px solid #d0d0ce;
  box-shadow: none;
}

/* Placeholder of input field */
[dir="ltr"]
  .ck.ck-labeled-field-view.ck-labeled-field-view_empty:not(
    .ck-labeled-field-view_focused
  ):not(.ck-labeled-field-view_placeholder)
  > .ck.ck-labeled-field-view__input-wrapper
  > .ck.ck-label,
.ck.ck-labeled-field-view
  > .ck.ck-labeled-field-view__input-wrapper
  > .ck.ck-label {
  transform: translate(12.68px, -6px) scale(0.75);
  padding-inline: 3px;
  background-color: white;
  color: #000000a3;
}

.ck.ck-link-actions,
.ck.ck-link-form {
  gap: 16px;
  padding: 16px;
}

.ck.ck-link-actions .ck-button.ck-link-actions__preview .ck-button__label {
  color: #0076a8;
  text-decoration: none;
}

.ck.ck-link-actions
  .ck-button.ck-link-actions__preview
  .ck-button__label:hover {
  text-decoration: none;
}

.ck-balloon-panel_with-arrow::before,
.ck-balloon-panel_with-arrow::after {
  display: none; /* Hides the top arrow */
}

.ck.ck-button,
a.ck.ck-button.ck-button_with-text {
  padding: 0px;
  border: none;
  align-content: center;
}

[dir="ltr"] .ck.ck-responsive-form > :not(:first-child),
[dir="ltr"] .ck.ck-link-actions .ck-button:not(:first-child) {
  margin-left: 0;
}

.ck.ck-link-actions .ck-button:not(:first-child):hover {
  cursor: pointer;
}

.ck.ck-button.ck-button-save {
  color: #0076a8;
}
.ck.ck-button.ck-button-save:hover {
  cursor: pointer;
}
.ck.ck-button.ck-button-cancel {
  color: #000000a3;
}

.ck.ck-button.ck-button-cancel:hover {
  cursor: pointer;
}
